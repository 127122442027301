import { createRouter, createWebHashHistory } from 'vue-router'
import Me from '../views/Me.vue'

const routes = [
  {
    path: '/',
    name: 'About Me',
    component: Me
  },
  {
    path: '/projects',
    name: 'Projekte',
    component: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue')
  },
  {
    path: '/changelog',
    name: 'Changelog',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "changelog" */ '../views/Changelog.vue')
  },
  {
    path: '/privacy',
    name: 'Datenschutz',
    beforeEnter() {location.href = 'https://policies.diafischchen.net/'}
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
