<template>
    <main class="mt-14 pb-28 relative">
        <div class="bg-gray-800 container flex sm:items-center gap-8 shadow-xl sm:flex-row flex-col">
            <div class="profile-img">
                <img src="../assets/images/profile.jpg" class="rounded-full mx-auto">
            </div>
            <div class="content">
                <h1 class="text-sky-100">diafischchen</h1>
                <div class="mt-2 text-xs sm:text-base">Hallo, man nennt mich diafischchen und ich entwickle Web-Anwendungen in PHP und JavaScript</div>
                <div class="icons mt-4">
                    <a href="https://github.com/diafischchen"><i class="fab fa-github"></i></a>
                    <a href="https://twitter.com/diafischchen"><i class="fab fa-twitter"></i></a>
                    <a href="https://youtube.com/c/diafischchen"><i class="fab fa-youtube"></i></a>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    
}
</script>

<style scoped>

.container {
    width: 1000px;
    max-width: 90%;
    @apply mx-auto p-8 rounded-2xl;
}

.content {
    width: 100%;
}

.profile-img img {
    width: 150px;
    height: 150px;
}

@media (min-width: 640px) { 

    .content {
        width: calc(100% - 200px - 32px);
    }

    .profile-img img {
        width: 200px;
        height: 200px;
    }

}

h1 {
    @apply sm:text-3xl text-xl font-semibold;
}

.icons {
    @apply text-sky-100 text-3xl flex justify-start items-center gap-4;
}

.icons > a {
    @apply hover:text-sky-300 transition;
}

</style>