<template>
    <div class="h-14 bg-sky-700 absolute bottom-0 left-0 right-0 flex justify-center items-center text-sm">
        <footer>© 2019 - 2022 diafischchen | <a href="https://diafischchen.net">diafischchen.net</a></footer>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

a {
    @apply text-sky-400 underline cursor-pointer;
}

</style>